<template>
  <div class="explanation">
    <el-dialog
      append-to-body
      :visible.sync="showTip"
      v-if="showTip"
      :title="showName"
      width="560px"
      center
      :before-close="handleClose"
      custom-class="appeal-dialog"
    >
      <div class="dialog-main">
        <el-form label-position="top" ref="approveForm">
          <el-form-item prop="remarks" label="驳回原因">
            <el-input
              v-filteremoji
              ref="remarks"
              type="textarea"
              rows="10"
              placeholder="驳回原因（选填，最多300字）"
              maxlength="300"
              show-word-limit
              v-model="approveForm.remarks"
              resize="none"
            />
          </el-form-item>
        </el-form>
      </div>
      <template slot="footer">
        <el-button type="primary" size="small" @click="handleApproveSubmit">确认驳回</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { postInnovationApprove } from '@/api/api_innovation'
import { postInnovationApprove as postTeamInnovationApprove } from '@/api/api_team'
import { postCustomerApprove } from '@/api/api_customer'
import '@/components/democracy/edit-dialog.scss'
export default {
  name: 'rejected',
  props: {
    rejectObj: {
      type: Object,
      default: () => {}
    },
    showDialog: {
      type: Boolean,
      default: false
    },
    moduleName: {
      type: String,
      default: 'approval'
    }
  },
  data() {
    return {
      approveForm: {
        remarks: '' //备注
      },
      showTip: this.showDialog
    }
  },
  computed: {
    showName() {
      return this.$parent.modelCode === 'innovate'
        ? '审批创新成果'
        : sessionStorage.getItem('code') === 'contribute'
        ? '审批团队贡献'
        : `审批${sessionStorage.getItem('name')}`
    }
  },
  methods: {
    async handleApi(params) {
      if (this.$parent.modelCode === 'innovate') {
        //创新成果
        return await postInnovationApprove(params)
      } else {
        if (sessionStorage.getItem('code') === 'contribute') {
          //团队贡献
          return await postTeamInnovationApprove(params)
        } else {
          //自定义维度
          return await postCustomerApprove(params)
        }
      }
    },
    handleApproveSubmit() {
      let { remarks } = this.approveForm
      let params = {
        operationState: 5, //审批状态-驳回
        processExampleId: this.rejectObj.processExampleId, //审批节点
        remarks //备注
      }
      if (this.$parent.modelCode === 'team') {
        if (sessionStorage.getItem('code') === 'contribute') {
          params = { ...params, contributeCode: this.rejectObj.innovateCode } //团队贡献
        } else {
          params = {
            ...params,
            customCode: this.rejectObj.innovateCode,
            dimensionCode: sessionStorage.getItem('code')
          } //自定义维度
        }
      } else {
        params = { ...params, innovateCode: this.rejectObj.innovateCode } //创新成果
      }
      this.loading = true
      this.handleApi(params)
        .then(res => {
          if (res.code === '000000') {
            this.$msgSuccess('操作成功')
            this.showTip = false
            this.$parent.rejectObj = {}
            let path = 'approval'
            if (this.$parent.modelCode === 'team') {
              path =
                sessionStorage.getItem('code') === 'contribute'
                  ? 'team-approval'
                  : sessionStorage.getItem('customize1')
                  ? 'customer-approval'
                  : 'customer2-approval'
            }
            this.$router.push({
              name: path,
              query: {
                moduleName: this.moduleName
              }
            })
            this.$parent.showRejectedDialog = false
          }
        })
        .catch(err => {
          console.log('err', err)
          this.$msgError('服务异常')
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleClose() {
      this.showTip = false
      this.$parent.showRejectedDialog = false
    }
  },
  watch: {
    showDialog(val) {
      this.showTip = this.showDialog
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/.el-dialog__body {
  height: 350px;
}
</style>
