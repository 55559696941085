<template>
  <div class="explanation">
    <el-dialog
      append-to-body
      :visible.sync="showTip"
      v-if="showTip"
      :title="showName"
      width="560px"
      center
      :before-close="handleClose"
      custom-class="appeal-dialog"
    >
      <div class="dialog-main">
        <el-form
          label-position="top"
          :model="approveForm"
          :show-message="true"
          ref="approveForm"
          :rules="rules"
        >
          <el-form-item
            prop="effectScope"
            :label="showTitleName"
            v-if="code === 'contribute' || code === 'innovate'"
          >
            <el-select
              v-model="approveForm.effectScope"
              placeholder="请选择"
              @change="handleLevelCode"
            >
              <el-option
                v-for="item in beddingList"
                :key="item.id"
                :label="item.proposalDesc"
                :value="item.proposalDesc"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            prop="resultLevel"
            :rules="{
              required: true,
              message: `请选择${showItemName}`,
              trigger: 'change'
            }"
            :label="showItemName"
            v-if="code === 'contribute' || code === 'innovate'"
          >
            <el-select v-model="approveForm.resultLevel" placeholder="请选择" @change="handleVal">
              <el-option
                :label="`${item.desc}`"
                :value="item.name"
                v-for="item in resultLevelList"
                :key="item.code"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            prop="score"
            label="评分"
            v-if="code === 'contribute' || code === 'innovate'"
            :rules="{ required: true, message: '请输入评分', trigger: 'blur' }"
          >
            <el-input
              v-filteremoji
              @keyup.native="keyU"
              type="text"
              placeholder="请输入评分"
              maxlength="10"
              v-model="approveForm.score"
              @focus="selVal($event)"
              :disabled="false"
              class="score"
            >
              <template #suffix>分</template>
            </el-input>
          </el-form-item>
          <el-form-item
            prop="score"
            label="奖励分数"
            v-if="code !== 'contribute' && code !== 'innovate'"
          >
            <el-input
              @keyup.native="keyU"
              v-filteremoji
              :placeholder="`请输入奖励分数，最高不能超过${max}分`"
              v-model="approveForm.score"
            />
          </el-form-item>
          <el-form-item prop="remarks" label="审批意见">
            <el-input
              v-filteremoji
              autofocus
              ref="remarks"
              type="textarea"
              rows="5"
              placeholder="审批意见（选填，最多300字）"
              maxlength="300"
              show-word-limit
              v-model="approveForm.remarks"
              resize="none"
            />
          </el-form-item>
        </el-form>
      </div>
      <template slot="footer">
        <el-button type="primary" size="small" @click="handleApproveSubmit">确认通过</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  postInnovationApprove,
  getResultlevelList,
  getInnovationBedding,
  getListDimension,
  getQueryResultLevel
} from '@/api/api_innovation'
import {
  postInnovationApprove as postTeamInnovationApprove,
  getcontributeQueryResultLevel as getTeamResultlevelList,
  getListCategory
} from '@/api/api_team'

import { postCustomerApprove, getMaxScore } from '@/api/api_customer'
import '@/components/democracy/edit-dialog.scss'
export default {
  name: 'pass',
  props: {
    passObj: {
      type: Object,
      default: () => {}
    },
    showDialog: {
      type: Boolean,
      default: false
    },
    moduleName: {
      type: String,
      default: 'approval'
    }
  },
  data() {
    var checkNumber = (rule, value, callback) => {
      if (String(value).trim().length < 1) {
        return callback(new Error('奖励分数不能为空'))
      } else {
        if (value < 0 || value > this.max) {
          return callback(new Error(`奖励分数输入范围0-${this.max}`))
        } else {
          return callback()
        }
      }
    }
    return {
      gradeObj: {},
      beddingList: [],
      rules: {
        score: [
          { validator: checkNumber, trigger: 'blur' },
          { required: true, message: '奖励分数不能为空' }
        ]
      },
      max: 0,
      code: sessionStorage.getItem('code'),
      resultLevelList: [],
      scoreList: [],
      effectScopeCode: null, //创新维度 || 提案类别code
      approveForm: {
        score: null, //奖励分数
        effectScope: '', //创新维度 || 提案类别
        resultLevel: '', //成果级别
        remarks: '' //备注
      },
      showTip: this.showDialog
    }
  },
  computed: {
    showName() {
      return this.$parent.modelCode === 'innovate'
        ? '审批创新成果'
        : sessionStorage.getItem('code') === 'contribute'
        ? '审批团队贡献'
        : `审批${sessionStorage.getItem('name')}`
    },
    showItemName() {
      return this.$parent.modelCode === 'innovate'
        ? '成果级别'
        : sessionStorage.getItem('code') === 'contribute'
        ? '贡献级别'
        : ''
    },
    showTitleName() {
      return this.$parent.modelCode === 'team' ? '提案类别' : '创新维度'
    }
  },
  methods: {
    /**
     * 创新应用层面列表查询
     */
    getBedding() {
      this.handleApi({}, 'level')
        .then(res => {
          //id proposalDesc
          this.beddingList = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    handleLevelCode(val) {
      this.effectScopeCode = this.beddingList.find(el => el.proposalDesc === val).id
      this.handleResultLevel(this.effectScopeCode, '')
    },
    selVal(event) {
      event.currentTarget.select()
    },
    keyU() {
      this.approveForm['score'] = this.approveForm['score'].replace(/[^\d.]/g, '') //仅保留数字和"."
      this.approveForm['score'] = this.approveForm['score'].replace(/\.{2,}/g, '.') //两个连续的"."仅保留第一个"."
      this.approveForm['score'] = this.approveForm['score']
        .replace('.', '$#*')
        .replace(/\./g, '')
        .replace('$#*', '.') //去除其他"."
      this.approveForm['score'] = this.approveForm['score'].replace(/^(\d+)\.(\d\d).*$/, '$1.$2') //限制只能输入两个小数
      if (this.approveForm['score'].indexOf('.') < 0 && this.approveForm['score'] != '') {
        //首位是0的话去掉
        this.approveForm['score'] = parseFloat(this.approveForm['score'])
      }
    },
    getScore(scoreMax, scoreMin) {
      let score = null
      if (typeof scoreMax !== 'object' && typeof scoreMin !== 'object') {
        score = scoreMin
      }
      if (
        (typeof scoreMax !== 'object' && typeof scoreMin === 'object') ||
        (typeof scoreMax === 'object' && typeof scoreMin !== 'object')
      ) {
        score = scoreMax || scoreMin
      }
      return score
    },
    handleVal(val) {
      let obj = this.resultLevelList.find(el => el.name === val)
      this.gradeObj = obj
      let { scoreMax, scoreMin } = obj
      let score = this.getScore(scoreMax, scoreMin)
      this.$set(this.approveForm, 'score', score)
    },
    async handleApi(params, type = 'list') {
      if (this.$parent.modelCode === 'innovate') {
        //创新成果
        if (type === 'list') {
          //列表  getResultlevelList
          return await getQueryResultLevel(params)
        } else if (type === 'level') {
          //提交
          return await getListDimension(params)
        } else {
          //提交
          return await postInnovationApprove(params)
        }
      } else {
        //团队贡献
        if (type === 'list') {
          //列表
          return await getTeamResultlevelList(params)
        } else if (type === 'level') {
          //提交
          return await getListCategory(params)
        } else {
          if (this.code === 'contribute') {
            //提交
            return await postTeamInnovationApprove(params)
          } else {
            return await postCustomerApprove(params)
          }
        }
      }
    },
    /**
     * 查询成果级别
     */
    async handleResultLevel(id, name) {
      const { data } = await this.handleApi({ effectId: id }, 'list')
      if (data) {
        this.resultLevelList = data ? data : []
        this.approveForm.resultLevel = name || this.resultLevelList[0].name
        let { scoreMax, scoreMin } = this.resultLevelList[0]
        let score = this.getScore(scoreMax, scoreMin)
        this.$set(this.approveForm, 'score', score)
        this.gradeObj = this.resultLevelList.find(el => el.name === this.approveForm.resultLevel)
        this.resultLevelList.forEach(el => {
          let desc = ''
          let { scoreMax, scoreMin } = el
          if (typeof scoreMax !== 'object' && typeof scoreMin !== 'object') {
            if (scoreMax > scoreMin) {
              desc = `(${scoreMin}-${scoreMax}分)`
            } else if (scoreMax === scoreMin) {
              desc = `(${scoreMax}分)`
            }
          }
          if (
            (typeof scoreMax !== 'object' && typeof scoreMin === 'object') ||
            (typeof scoreMax === 'object' && typeof scoreMin !== 'object')
          ) {
            let newVal = scoreMin || scoreMax
            desc = `(${newVal}分)`
          }
          desc = `${el.name}${desc}`
          this.$set(el, 'desc', desc)
        })
      }
    },
    /**
     * 查询最高奖励分数
     */
    handlemaxScore() {
      getMaxScore({ code: sessionStorage.getItem('code') })
        .then(res => {
          let { score } = res.data
          this.max = score
        })
        .catch(err => {
          console.log(err)
        })
    },
    handleApproveSubmit() {
      this.$refs['approveForm'].validate(valid => {
        if (valid) {
          let { remarks, resultLevel, score } = this.approveForm

          let { scoreMax: max, scoreMin: min, name } = this.gradeObj
          if (typeof min !== 'object' && typeof max != 'object') {
            //存在区间,例如1-3
            if (max > min) {
              if (score > max || score < min) {
                this.$msgError(`${name}评分范围${min}-${max}分,请修正`)
                return false
              }
            } else if (max === min) {
              // 最大值和最小值相等
              if (score > max || score < max) {
                this.$msgError(`${name}评分范围为${max}分,请修正`)
                return false
              }
            }
          }
          //只有一个值，max || min
          if (
            (typeof max !== 'object' && typeof min === 'object') ||
            (typeof min !== 'object' && typeof max === 'object')
          ) {
            let newVal = max || min
            if (score > newVal || score < newVal) {
              this.$msgError(`${name}评分范围为${newVal}分,请修正`)
              return false
            }
          }
          //不存在 max&&min,分数<100
          if (typeof max === 'object' && typeof min === 'object') {
            if (score > 100 || score < 0) {
              this.$msgError(`${name}评分范围100分以内,请修正`)
              return false
            }
          }
          let params = {
            effectScope: Number(this.effectScopeCode || this.passObj.effectId), //影响范围
            operationState: 4, //审批状态-通过
            processExampleId: this.passObj.processExampleId, //审批节点
            remarks, //备注
            resultLevel, //	成果级别
            score //评分
          }
          if (this.$parent.modelCode === 'team') {
            if (sessionStorage.getItem('code') === 'contribute') {
              params = { ...params, contributeCode: this.passObj.innovateCode } //团队贡献
            } else {
              if (score > this.max) {
                this.$warning(`奖励分数不能超过${this.max}分！`)
                return false
              }
              params = {
                processExampleId: this.passObj.processExampleId, //审批节点
                remarks, //备注
                score,
                operationState: 4, //审批状态-通过
                customCode: this.passObj.innovateCode,
                dimensionCode: sessionStorage.getItem('code')
              }
            }
          } else {
            params = { ...params, innovateCode: this.passObj.innovateCode } //创新成果
          }
          this.handleApi(params, 'add')
            .then(res => {
              if (res.code === '000000') {
                this.$msgSuccess('操作成功')
                this.showTip = false
                this.$parent.passObj = {}
                this.$parent.showPassDialog = false
                this.approveForm = {
                  score: null,
                  effectScope: '',
                  resultLevel: '',
                  remarks: ''
                }
                let path = 'approval'
                if (this.$parent.modelCode === 'team') {
                  path =
                    sessionStorage.getItem('code') === 'contribute'
                      ? 'team-approval'
                      : sessionStorage.getItem('customize1')
                      ? 'customer-approval'
                      : 'customer2-approval'
                }
                this.$router.push({
                  name: path,
                  query: {
                    moduleName: this.moduleName
                  }
                })
              }
            })
            .catch(err => {
              console.log('err', err)
              this.$msgError('服务异常')
            })
        } else {
          return false
        }
      })
    },
    handleClose() {
      this.showTip = false
      this.$parent.passObj = {}
      this.$parent.showPassDialog = false
      this.approveForm = {
        score: null,
        effectScope: '',
        resultLevel: '',
        remarks: ''
      }
    }
  },
  watch: {
    async showDialog(val) {
      this.showTip = this.showDialog
      if (this.showTip) {
        if (this.$parent.modelCode === 'team' && sessionStorage.getItem('code') !== 'contribute') {
          this.handlemaxScore()
          this.approveForm.score = this.passObj?.score ?? null
        } else {
          this.approveForm.effectScope = this.passObj.innovateLevelName
          this.effectScopeCode = this.passObj.effectId
          this.getBedding()
          await this.handleResultLevel(this.effectScopeCode, this.passObj?.resultLevel ?? '')
          this.approveForm.score = this.passObj?.score ?? null
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/.el-input__inner {
  padding: 0 10px;
  color: #3d7eff;
}
/deep/.el-input.is-disabled .el-input__inner {
  background-color: $white;
}
/deep/.el-dialog__body {
  height: 357px;
}
.score {
  /deep/.el-input__suffix {
    left: 0;
    margin-left: -60%;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
  }
}
</style>
