<template>
  <div class="personal-item">
    <user-avatar :obj="{ avatar: obj.avatar, name: obj.name }" @click.native="tolink" />
    <div class="box-left">
      <div class="title">
        <span>{{ obj.name }}</span>
        <slot name="reward"></slot>
      </div>
      <p class="content">{{ obj.job }}</p>
    </div>
    <slot name="status"></slot>
    <slot name="tip"></slot>
  </div>
</template>

<script>
import userAvatar from '@/components/initiate/user-avatar'
export default {
  name: 'user-card',
  props: {
    obj: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    userAvatar
  },
  computed: {
    avatar: {
      get() {
        return require('../../assets/images/profile.png')
      }
    }
  },
  methods: {
    tolink() {
      if (this.obj.userId) {
        this.$router.push({
          name: 'homepage-navigation',
          query: { userId: this.obj.userId }
        })
      }
    },
    errorHandler() {
      return true
    }
  }
}
</script>

<style lang="scss" scoped>
.personal-item {
  display: flex;
  flex-shrink: 0;
  background: $white;
  border-radius: 5px;
  padding: 15px 0 15px 15px;
  align-items: center;
  .box-left {
    margin-left: 10px;
    flex: 1;
    .title {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: $lightblack;
      line-height: 16px;
      margin-bottom: 7px;
    }
    .content {
      font-size: 12px;
      font-weight: 400;
      color: $darkgrey;
      line-height: 16px;
    }
  }
}
</style>
