<template>
  <div class="explanation">
    <el-dialog
      append-to-body
      v-loading="loading"
      :visible.sync="showTip"
      v-if="showTip"
      :title="showName"
      width="560px"
      center
      :before-close="handleClose"
      custom-class="appeal-dialog"
    >
      <div class="item-box" v-if="approvePeople.userName">
        <div class="sele-box">
          {{ approvePeople.userName
          }}<i class="el-icon-error" @click="deletePerson(approvePeople)" />
        </div>
      </div>
      <tojoy-select
        v-else
        ref="entrustRef"
        v-model="approvePeople"
        :disabled="false"
        :multiple="false"
        :remote="true"
        :isItemObj="true"
        placeholder="请输入姓名"
      />
      <template slot="footer">
        <el-button type="primary" size="small" @click="handleApproveSubmit">确定</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import TojoySelect from '@/components/common/tojoy-select/person-selected'
import { postInnovationEntrust } from '@/api/api_innovation'
import { postInnovationEntrust as postTeamInnovationEntrust } from '@/api/api_team'
postCustomerEntrust
import { postCustomerEntrust } from '@/api/api_customer'
import '@/components/democracy/edit-dialog.scss'
export default {
  name: 'entrust',
  components: {
    TojoySelect
  },
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    entrustObj: {
      type: Object,
      default: () => {}
    },
    moduleName: {
      type: String,
      default: 'all'
    }
  },
  data() {
    return {
      loading: false,
      approvePeople: {},
      showTip: this.showDialog
    }
  },
  computed: {
    showName() {
      return this.$parent.modelCode === 'innovate'
        ? '审批创新成果'
        : sessionStorage.getItem('code') === 'contribute'
        ? '审批团队贡献'
        : `审批${sessionStorage.getItem('name')}`
    }
  },
  methods: {
    deletePerson(data) {
      this.$set(data, 'userName', '')
      this.$set(data, 'userId', null)
    },
    async handleApi(params) {
      if (this.$parent.modelCode === 'innovate') {
        //创新成果
        return await postInnovationEntrust(params)
      } else {
        //团队贡献
        if (sessionStorage.getItem('code') === 'contribute') {
          return await postTeamInnovationEntrust(params)
        } else {
          return await postCustomerEntrust(params)
        }
      }
    },
    handleApproveSubmit() {
      let entrustId = Object.keys(this.approvePeople).length > 0 ? this.approvePeople.userId : null
      if (!entrustId) {
        this.$msgError('委托人不能为空!')
        return false
      }
      let params = {
        entrustId: entrustId,
        processExampleId: this.entrustObj.processExampleId //审批节点
      }
      if (this.$parent.modelCode === 'team') {
        //团队贡献
        if (sessionStorage.getItem('code') === 'contribute') {
          params = { ...params, contributeCode: this.entrustObj.innovateCode }
        } else {
          //自定义维度
          params = {
            ...params,
            customCode: this.entrustObj.innovateCode,
            dimensionCode: sessionStorage.getItem('code')
          }
        }
        params = { ...params, contributeCode: this.entrustObj.innovateCode }
      } else {
        params = { ...params, innovateCode: this.entrustObj.innovateCode } //创新成果
      }
      this.loading = true
      this.handleApi(params)
        .then(res => {
          if (res.code === '000000') {
            this.$msgSuccess('操作成功')
            this.showTip = false
            this.$parent.entrustObj = {}
            this.$parent.showEntrustDialog = false
            this.approvePeople = {}
            let path = 'approval'
            if (this.$parent.modelCode === 'team') {
              path = sessionStorage.getItem('code' === 'contribute')
                ? 'team-approval'
                : sessionStorage.getItem('customize1')
                ? 'customer-approval'
                : 'customer2-approval'
            }
            this.$router.push({
              name: path,
              query: {
                moduleName: this.moduleName
              }
            })
          }
        })
        .catch(err => {
          console.log('err', err)
          this.$msgError('服务异常')
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleClose() {
      this.showTip = false
      this.$parent.entrustObj = {}
      this.$parent.showEntrustDialog = false
      this.approvePeople = {}
    }
  },
  watch: {
    showDialog(val) {
      this.showTip = this.showDialog
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/.el-dialog__body {
  height: 350px;
}
.item-box {
  border-bottom: 1px solid $linecolor;
  &:hover {
    border-bottom: 1px solid $blue;
  }
}
.sele-box {
  border-radius: 17px;
  border: 1px solid $grey600;
  font-size: 12px;
  font-weight: 400;
  color: $lightblack;
  line-height: 24px;
  padding: 0 8px 0 12px;
  display: inline-block;
  margin-top: 17px;
  margin-bottom: 17px;
  &:hover {
    color: $blue;
    border-color: $blue;
    cursor: pointer;
  }

  i {
    cursor: pointer;
    width: 13px;
    height: 13px;
    color: $grey600;
    margin-left: 5px;
  }
}
/deep/.el-input__inner {
  height: 64px;
}
</style>
