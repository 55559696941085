<template>
  <div class="record">
    <el-timeline>
      <el-timeline-item
        v-for="(activity, index) in list"
        :key="index"
        :icon="activity.operationState === 3 ? '' : 'el-icon-check'"
        :color="activity.operationState === 3 ? '#cccccc' : '#06AE56'"
        :size="activity.operationState === 3 ? 'normal' : 'large'"
        :timestamp="activity.createTime"
      >
        <template>
          <div class="big-title">
            <span>{{ activity.nodeName || '' }}</span>
            <span class="num" v-if="activity.operationState === 6">{{ score || '' }}</span>
          </div>
          <div class="content">{{ activity.operationName }}</div>
          <p class="content remarks">{{ activity.case }}</p>
        </template>
      </el-timeline-item>
    </el-timeline>
  </div>
</template>

<script>
export default {
  props: {
    score: {
      default: String
    },
    list: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss">
.record {
  padding-left: 30px;
  padding-right: 20px;
  overflow: auto;
  .el-timeline-item__node--large {
    width: 16px;
    height: 16px;
  }
  .el-timeline-item__node--normal {
    left: 0;
    width: 10px;
    height: 10px;
  }
  /deep/.el-timeline-item__timestamp.is-bottom {
    font-size: 12px;
  }
  /deep/.el-timeline-item {
    padding-bottom: 30px;
  }
  .el-timeline-item__tail {
    &::before {
      content: '';
      position: absolute;
      left: -2px;
      width: 2px;
      height: 26px;
      display: inline-block;
      background: $white;
    }
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: -2px;
      width: 2px;
      height: 10px;
      display: inline-block;
      background: $white;
    }
  }
  .big-title {
    font-size: 15px;
    font-weight: 500;
    color: $lightblack;
    // line-height: 20px;
    margin-bottom: 10px;
  }
  .num {
    font-size: 12px;
    font-weight: 400;
    color: $blue;
    line-height: 12px;
    margin-left: 15px;
  }
  .content {
    font-size: 12px;
    font-weight: 400;
    color: $darkgrey;
    line-height: 12px;
    margin-bottom: 10px;
    word-wrap: break-word;
  }
  .remarks {
    line-height: 14px;
    margin-bottom: 8px;
  }
}
</style>
