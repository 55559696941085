<template>
  <div class="explanation">
    <el-dialog
      :visible.sync="showTip"
      v-if="showTip"
      :title="type === 1 ? '填写说明' : '审批说明'"
      width="560px"
      center
      :before-close="handleClose"
      custom-class="appeal-dialog"
    >
      <div class="dialog-main">
        <pre class="content">{{ submitDesc }}</pre>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import '@/components/democracy/edit-dialog.scss'
import { getSubmitDesc, getApproveDesc } from '@/api/api_innovation'
import {
  getSubmitDesc as geTeamtSubmitDesc,
  getApproveDesc as getTeamApproveDesc
} from '@/api/api_team'
import {
  getSubmitDesc as getCustomerSubmitDesc,
  getApproveDesc as getCustomerApproveDesc
} from '@/api/api_customer'
export default {
  name: 'explanation',
  props: {
    type: {
      type: Number,
      default: 2 //1 填写说明  2审批说明
    },
    modelType: {
      type: String,
      default: 'initiate' //team 团队贡献
    },
    showDialog: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      submitDesc: '',
      showTip: this.showDialog
    }
  },
  methods: {
    async handleApi() {
      if (this.modelType === 'initiate') {
        //创新成果
        if (this.type === 1) {
          return await getSubmitDesc() //填写说明
        } else {
          return await getApproveDesc() //审批说明
        }
      } else {
        //团队贡献
        if (sessionStorage.getItem('code') === 'contribute') {
          if (this.type === 1) {
            return await geTeamtSubmitDesc() //填写说明
          } else {
            return await getTeamApproveDesc() //审批说明
          }
        } else {
          //自定义维度
          if (this.type === 1) {
            return await getCustomerSubmitDesc() //填写说明
          } else {
            return await getCustomerApproveDesc() //审批说明
          }
        }
      }
    },
    getSubmitDescList() {
      this.handleApi()
        .then(res => {
          if (this.type === 1) {
            let { submitDesc } = res.data
            this.submitDesc = submitDesc.replace(/(^\s*)/g, '')
          } else {
            let { approveDesc } = res.data
            this.submitDesc = approveDesc.replace(/(^\s*)/g, '')
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    handleClose() {
      this.showTip = false
      this.$parent.showDialog = false
    }
  },
  watch: {
    showDialog(val) {
      this.showTip = this.showDialog
      if (this.showDialog) {
        this.submitDesc = ''
        this.getSubmitDescList()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.explanation {
  .dialog-main {
    margin-top: 20px;
    .content {
      font-size: 14px;
      font-weight: 400;
      color: $lightblack;
      line-height: 22px;
      white-space: pre-wrap !important;
      word-wrap: break-word !important;
      *white-space: pre-wrap !important;
    }
  }
  /deep/.el-dialog__body {
    height: 350px;
  }
}
</style>
